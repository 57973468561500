<template>
	<NuxtLink :to="action" :target="target" class="btn" @mouseenter="soundStore.playHover" @click.native="executeAction">
		<span class="dot"></span>
		<span class="label">{{ label }}</span>
		<span class="arrow">
			<ArrowRight />
		</span>
	</NuxtLink>
</template>

<script setup>
import ArrowRight from '~/assets/svg/Arrow-Right.svg?component'
const soundStore = useSoundStore()
const props = defineProps(['label', 'action', 'target'])

const gtm = useGtm()

const executeAction = () => {

	soundStore.playClick()

	gtm.trackEvent({
		event: 'interaction',
		category: 'Call To Action',
		action: 'click',
		label: props.label,
		value: props.action
	})
}

</script>
